<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <card>
          <form class="form-horizontal">
            <h4 slot="header" class="card-title">
              Add Brand
              <p v-if="false && !maxReached" class="category">You can add up to {{ brandSlotsRemaining }} more brands on your PLACEHOLDER Plan.</p>
            </h4>
            <div class="row">
              <label class="col-md-3 col-form-label">Brand Logo</label>
              <div class="col-md-9">
                <div style="width: 200px;">
                  <single-image-uploader @tempFileIdUpdated="setTempImageId" />
                </div>
              </div>

              <label class="col-md-3 col-form-label">Brand Name</label>
              <div class="col-md-9">
                <fg-input v-model="newBrand.name" maxlength="18"></fg-input>
              </div>

              <label class="col-md-3 col-form-label">Redirect URL</label>
              <div class="col-md-9">
                <fg-input placeholder="ex: https://www.mydomain.com/landingpage" v-model="newBrand.redirect_url"></fg-input>
              </div>
            </div>

            <div class="card-footer text-right">
              <p-button v-if="!loading" type="primary" round @click.prevent="addBrand()">Submit</p-button>
              <p-button v-if="loading" type="primary" round disabled="disabled">
                <i class="fa fa-circle-o-notch fa-spin"></i>
                Submit
              </p-button>
            </div>
          </form>
        </card> <!-- end card -->
      </div> <!-- end col-md-12 -->
    </div>

    <div class="row">
      <div v-for="(brand, index) in user.account.brands" class="col-lg-3" :key="brand.id">
        <div class="card" style="padding-top:10px;">
          <div class="text-center">
            <h3>{{brand.name}}</h3>
          </div>
          <div class="text-center" style="height: 140px; display: flex; align-items: center;">
            <div class="img-container">
              <img v-if="brand.logo_img" :src="brand.logo_img" :alt="brand.name">
              <span v-if="!brand.logo_img">{NO BRAND LOGO}</span>
            </div>
          </div>
          <hr>
          <div class="text-center" style="height: 40px;">
            <p-button type="primary" round size="sm" v-if="!brand.deleting" @click.prevent="deleteBrand(brand, index)">Delete</p-button>
            <p-button type="primary" round size="sm" v-if="brand.deleting" disabled="disabled">
              <i class="fa fa-circle-o-notch fa-spin"></i>
              Deleting...
            </p-button>

            <p-button type="primary" round size="sm" v-if="brand.isArchived && !brand.unarchiving" @click.prevent="unArchiveBrand(brand)">Un-Archive</p-button>
            <p-button type="primary" round size="sm" v-if="brand.unarchiving" disabled="disabled">
              <i class="fa fa-circle-o-notch fa-spin"></i>
              Un-Archiving...
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="false">
      <div class="col-md-4 pull-right">
        <div style="float: right;">
          <p class="category">Show Archived Brands</p>
          <p-switch v-model="showArchived" type="primary" on-text="ON" off-text="OFF"></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import BrandService from 'src/services/brand.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'

  // import swal from 'sweetalert2'
  // import {mapFields} from 'vee-validate'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import SingleImageUploader from 'src/components/SharedComponents/SingleImageUploader.vue'

  export default{
    components: {
      PSwitch,
      SingleImageUploader
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      maxReached () {
        return false
      },
      brandSlotsRemaining () {
        return 5
      }
    },
    mixins: [NotifyMixin],
    data () {
      return {
        brands: [],
        newBrand: {
          name: '',
          imgTempFileId: '',
          redirect_url: ''
        },
        uploadProgress: 0,
        loading: false,
        modelValidations: {
          name: {
            required: true
          }
        },
        showArchived: false
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      async init () {
        this.brands = this.user.account.brands
      },
      setTempImageId (newId) {
        this.newBrand.imgTempFileId = newId
      },
      async addBrand () {
        try {
          this.loading = true
          await BrandService.create(this.newBrand)
          this.showNotify('Brand created', 'success', 'Success')
          this.loading = false
          this.me()
        } catch (e) {
          let msg = e.response.data.message
          if (e.response.data.errors) {
            const keys = Object.keys(e.response.data.errors)
            keys.forEach((key, index) => {
                msg += ' - ' + e.response.data.errors[key]
            });
          }
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      },
      validateAddBrand () {
        this.loading = true
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.addBrand()
          } else {
            this.loading = false
          }
        })
      },
      async deleteBrand (brand, index) {
        if (this.user.options.active_brand_id === brand.id) {
          this.showNotify("If you wish to delete " + brand.name + " then please select a different active brand on the top right of the screen first.", 'danger', 'Failed')
          return
        }
        brand.deleting = true
        Vue.set(this.brands, index, brand)
        try {
          await BrandService.destroy(brand.id)
          this.me()
        } catch (e) {
          this.showNotify(e.response.data.message, 'danger', 'Failed')
          brand.deleting = false
          Vue.set(this.brands, index, brand)
        }
      },
      // async deleteBrand (brand) {
      //   try {
      //     let brandId = brand.id
      //     brand.deleting = true
      //     brand.key = this.$uuid.v4()
      //     let data = {}
      //     data.userToken = await firebase.auth().currentUser.getIdToken()
      //     data.accountId = this.user.accountId
      //     data.brandId = brandId
      //     let accountDeleteBrand = firebase.functions().httpsCallable('accountDeleteBrand')
      //     let result = await accountDeleteBrand(data)
      //     brand.deleting = false
      //     brand.key = this.$uuid.v4()
      //     this.showNotify(`Brand ${brand.name} deleted.`, 'success', 'Success')
      //     this.brandsTable.splice(this.brandIdIndexMap.get(brandId), 1)
      //   } catch (err) {
      //     brand.deleting = false
      //     brand.key = this.$uuid.v4()
      //     swal.fire({
      //       title: 'Cannot Be Deleted',
      //       text: `This brand cannot be deleted as it contains existing Authentic.net codes. Would you like to archive it instead?`,
      //       type: 'warning',
      //       showCancelButton: true,
      //       confirmButtonClass: 'btn btn-info btn-fill',
      //       cancelButtonClass: 'btn btn-danger btn-fill',
      //       confirmButtonText: 'Yes, archive it!',
      //       buttonsStyling: false
      //     }).then(async (result) => {
      //       if (result.value) await this.archiveBrand(brand)
      //     })
      //   }
      // },
      isDeleting (brand) {
        return brand.deleting
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      }
    },
    watch: {
      user: function (newUser, oldUser) {
        this.brands = this.user.account.brands
      },
      showArchived: function (newFrom, oldFrom) {
        this.getBrands()
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
.img-container {
  max-width: 140px;
  max-height: 140px;
  margin: auto;
  text-align: center;
}
.img-container img {
  max-width: 150px;
  max-height: 100px;
}
</style>
